<template>
  <div>
    <LoadingLayout :loading="spinner" />

    <AlertDialog
      :show="showErr"
      type="failed"
      :msg="errMsg"
      @close="showErr = false"
    />

    <CustomDialog
      :show="showWarningDialog"
      type="warning"
      :msg="warningMsg"
      @cancel="handleCancel()"
      @onOkay="handleSubmit()"
    />

    <div v-if="!spinner">
      <HeaderLayout :env="env" :type="1" />
      <b-container class="mt--5">
        <b-row class="justify-content-center">
          <b-col col lg="6" md="9">
            <b-card class="shadow">
              <b-form @submit.prevent="onSubmit()">
                <label class="form-control-label">
                  {{ $t("register.dateTime") }}
                </label>
                <b-row>
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-datepicker
                      v-model="startDate"
                      :value="startDate"
                      :min="min"
                      :max="endDatemax"
                      today-button
                      reset-button
                      close-button
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      }"
                      trim
                      @input="setEndDate()"
                      locale="en-GB"
                    ></b-form-datepicker>
                  </b-col>
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-timepicker
                      v-model="startTime"
                      :value="startTime"
                      ref="picker"
                      popover-visibility="focus"
                      locale="en"
                      @input="setEndTime()"
                    ></b-form-timepicker>
                  </b-col>
                </b-row>

                <label class="form-control-label">
                  {{ $t("register.dateTime2") }}
                </label>
                <b-row>
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-datepicker
                      v-model="endDate"
                      :value="endDate"
                      :min="endDateMin"
                      :max="endDatemax"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      }"
                      locale="en-GB"
                      trim
                    ></b-form-datepicker>
                  </b-col>
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-timepicker
                      v-model="endTime"
                      :value="endTime"
                      locale="en"
                    ></b-form-timepicker>
                  </b-col>
                </b-row>

                <b-form-group>
                  <label class="form-control-label">
                    {{ $t("title") }}
                  </label>
                  <b-form-input
                    v-model="title"
                    type="text"
                    maxlength="50"
                    minlength="3"
                    aria-describedby="title"
                    trim
                  ></b-form-input>
                </b-form-group>

                <template>
                  <div v-if="visitTypeOptions && visitTypeOptions.length">
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <label class="form-control-label">
                        {{ $t("select_type") }}
                      </label>
                      <b-form-radio
                        v-for="(item) in visitTypeOptions"
                        :key="item"
                        v-model="selectedVisitType"
                        :aria-describedby="ariaDescribedby"
                        name="some-radios"
                        :value="item"
                      >
                        {{ $t(item) }}
                      </b-form-radio>
                    </b-form-group>
                  </div>
                  <div v-else>
                    <!-- Fallback UI -->
                    Loading options...
                  </div>
                </template>
                
                <div v-if="selectedVisitType == this.visitType2.UNIT">
                  <label class="form-control-label" v-if="env.isLanded == true">
                    {{ $t("select_phase") }}
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <label
                    class="form-control-label"
                    v-if="env.isLanded == false"
                  >
                    {{ $t("select_block") }}
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <b-form-select
                    v-model="selectedBlock"
                    :options="blockOptions"
                    @change="getFloors()"
                    value-field="id"
                    text-field="blockName"
                    required
                  >
                  </b-form-select>
                </div>
                <br v-if="selectedVisitType == this.visitType2.UNIT" />

                <div v-if="selectedBlock && selectedVisitType == this.visitType2.UNIT">
                  <label class="form-control-label" v-if="env.isLanded == true">
                    {{ $t("select_jalan") }}
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <label
                    class="form-control-label"
                    v-if="env.isLanded == false"
                  >
                    {{ $t("select_floor") }}
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <b-form-select
                    v-model="selectedFloor"
                    :options="floorOptions"
                    text-field="floorName"
                    value-field="id"
                    @change="getUnits()"
                    required
                  ></b-form-select>
                </div>
                <br v-if="selectedBlock && selectedVisitType == this.visitType2.UNIT" />

                <div v-if="selectedFloor && selectedVisitType == this.visitType2.UNIT">
                  <label class="form-control-label">
                    {{ $t("select_unit") }}
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <b-form-select
                    v-model="selectedUnit"
                    :options="unitOptions"
                    value-field="id"
                    text-field="unitName"
                    required
                  ></b-form-select>
                </div>
                <br v-if="selectedFloor && selectedVisitType == this.visitType2.UNIT" />

                <h3>{{ $t("vstr-rsdnt.visitorTitle") }}</h3>

                <b-form-group>
                  <label class="form-control-label">
                    {{ $t("vstr-rsdnt.email") }}
                  </label>
                  <b-form-input
                    v-model="email"
                    :placeholder="$t('vstr-rsdnt.emailPh')"
                    :disabled="disabledEmail"
                    type="email"
                    maxlength="50"
                    aria-describedby="inv-pin-feedback"
                    trim
                  ></b-form-input>
                  <!-- <b-form-invalid-feedback
                      id="inv-pin-feedback"
                      >{{ invEmailMsg }}</b-form-invalid-feedback
                    > -->
                </b-form-group>

                <b-form-group>
                  <label class="form-control-label">
                    {{ $t("vstr-rsdnt.carPlate") }}&nbsp;
                  </label>
                  <b-form-input
                    v-model="carPlateNo"
                    :placeholder="$t('vstr-rsdnt.carPlatePh')"
                    :disabled="disabledCarPlate"
                    type="text"
                    maxlength="15"
                    aria-describedby="inv-pin-feedback"
                    trim
                  ></b-form-input>
                  <!-- <b-form-invalid-feedback
                      id="inv-pin-feedback"
                      >{{ invEmailMsg }}</b-form-invalid-feedback
                    > -->
                </b-form-group>

                <!-- <b-form-group>
                  <label class="form-control-label">
                    Total visitor&nbsp;
                    <span class="text-danger">*</span>
                  </label>
  
                  <NumberInput
                    :value="$v.form.totalVisitor.$model"
                    :min="1"
                    :step="1"
                    @changes="setTotalVisitor"
                  />
                  </b-form-group>-->

                <hr />

                <div v-for="(visitor, i) in visitorList" :key="i">
                  <h3>
                    <span class="mr-3"
                      >{{ $t("vstr-rsdnt.visitor") }} {{ i + 1 }}</span
                    >

                    <b-button
                      variant="success"
                      size="sm"
                      v-if="
                        maxVisitor > 1 &&
                        i == visitorList.length - 1 &&
                        i != maxVisitor - 1
                      "
                      @click="addVisitor()"
                    >
                      <md-icon
                        class="text-white icon-size"
                        style="font-size: 14px !important"
                        >add</md-icon
                      >
                    </b-button>

                    <b-button
                      variant="danger"
                      size="sm"
                      v-if="i != 0"
                      @click="removeVisitor(i)"
                    >
                      <md-icon
                        class="text-white icon-size"
                        style="font-size: 14px !important"
                        >remove</md-icon
                      >
                    </b-button>
                  </h3>

                  <div v-for="(info, j) in visitor.addInfo" :key="i + '.' + j">
                    <b-form-group v-if="info.show">
                      <label class="form-control-label">
                        {{ info.label }}
                        <span class="text-danger" v-if="info.required"
                          >&nbsp;*</span
                        >
                      </label>

                      <div v-if="info.type == 'DATE'">
                        <b-form-datepicker
                          v-model="info.value"
                          :value="info.value"
                          :date-format-options="{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          trim
                        ></b-form-datepicker>
                      </div>

                      <div v-else-if="info.type == 'DATE_TIME'">
                        <b-row>
                          <b-col lg="6" class="mb-2">
                            <b-form-datepicker
                              v-model="info.date"
                              :value="info.date"
                              :date-format-options="{
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              }"
                              trim
                            ></b-form-datepicker>
                          </b-col>
                          <b-col lg="6">
                            <b-form-timepicker
                              v-model="info.time"
                              :value="info.time"
                              locale="en"
                            ></b-form-timepicker>
                          </b-col>
                        </b-row>
                      </div>

                      <div v-else-if="info.type == 'LONG_TEXT'">
                        <b-form-textarea
                          v-model="info.value"
                          :value="info.value"
                          maxlength="300"
                          rows="5"
                          trim
                        ></b-form-textarea>
                      </div>

                      <div v-else>
                        <b-form-input
                          v-model="info.value"
                          :value="info.value"
                          maxlength="50"
                          trim
                        ></b-form-input>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <b-form-group>
                  <label class="form-control-label">
                    {{ $t("vstr-rsdnt.attachment1") }}&nbsp;
                  </label>
                  <b-form-file ref="fileInput1" name="file_upload[]" :multiple="false" :file-name-formatter="formatAssetUpload" no-drop placeholder="Click to choose" accept=".png,.jpg,.jpeg,.gif,.bmp,.webp,.pdf" @input="handleFileUpload($event, 1)"></b-form-file>
                  <label class="form-control-label attachment-margin">
                    {{ $t("vstr-rsdnt.attachment2") }}&nbsp;
                  </label>
                  <b-form-file ref="fileInput2" name="file_upload[]" :multiple="false" :file-name-formatter="formatAssetUpload" no-drop placeholder="Click to choose" accept=".png,.jpg,.jpeg,.gif,.bmp,.webp,.pdf" @input="handleFileUpload($event, 2)"></b-form-file>
                  <label class="form-control-label attachment-margin">
                    {{ $t("vstr-rsdnt.attachment3") }}&nbsp;
                  </label>
                  <b-form-file ref="fileInput3" name="file_upload[]" :multiple="false" :file-name-formatter="formatAssetUpload" no-drop placeholder="Click to choose" accept=".png,.jpg,.jpeg,.gif,.bmp,.webp,.pdf" @input="handleFileUpload($event, 3)"></b-form-file>
                </b-form-group>

                <div class="d-flex flex-row mt-4">
                  <div class="d-flex flex-column mx-auto">
                    <vue-recaptcha
                      ref="recaptcha"
                      v-model="$v.form.recaptcha.$model"
                      :sitekey="env.RECAPCHA_SITE_KEY"
                      :loadRecaptchaScript="true"
                      @verify="validateRecaptha"
                    ></vue-recaptcha>
                  </div>
                </div>

                <div class="mt-4">
                  <b-button
                    type="submit"
                    block
                    variant="primary"
                    :disabled="loading"
                  >
                    <b-spinner small class="mr-2" v-if="loading"></b-spinner>
                    {{ $t("register.submitBtn") }}
                  </b-button>
                </div>
              </b-form>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <FooterLayout :env="env" />
    </div>
  </div>
</template>
  
  <script>
import HeaderLayout from "@/view/HeaderLayout";
import FooterLayout from "@/view/FooterLayout";
import LoadingLayout from "@/view/LoadingLayout";
import AlertDialog from "@/view/dialog/AlertDialog";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
import moment from "moment";
import CustomDialog from "@/view/dialog/CustomAlertDialog";

const VisitType = {
  MANAGEMENT_OFFICE: 'MANAGEMENT_OFFICE',
  UNIT: 'UNIT',
  CONTRACTOR: 'CONTRACTOR',
  CLUBHOUSE: 'CLUB_HOUSE',
};

export default {
  components: {
    HeaderLayout,
    FooterLayout,
    LoadingLayout,
    AlertDialog,
    VueRecaptcha,
    CustomDialog,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    return {
      LOCAL_STORAGE_FORM: "rgwif",
      form: {
        pin: "",
        recaptcha: "",
      },
      min: minDate,
      endDateMin: minDate,
      endDatemax: moment(today).add(this.maxDuration, this.durationType).format("YYYY-MM-DD"),
      startDate: moment(today).format("YYYY-MM-DD"),
      startTime: "",
      endDate: "",
      endTime: "",
      title: "",
      selectedVisitType: VisitType,
      selectedBlock: null,
      blockOptions: [],
      selectedFloor: null,
      floorOptions: [],
      selectedUnit: null,
      unitOptions: [],

      maxVisitor: 1,
      disabledEmail: false,
      disabledCarPlate: false,
      visitorList: [],
      addInfo: "",
      email: "",
      carPlateNo: "",

      spinner: true,
      loading: false,

      showErr: false,
      errMsg: "",

      showWarningDialog: false,
      warningMsg: "",
      format: "hh:mm:ss",

      maxDuration: 7,
      durationType: 'day',

      visitTypeOptions: [],

      visitType2: VisitType,
      selectedFiles1: File,
      selectedFiles2: File,
      selectedFiles3: File,
    };
  },
  props: {
    env: Object,
  },
  validations: {
    form: {
      recaptcha: { required },
    },
  },
  mixins: [validationMixin],
  async created() {
    
  },
  async mounted() {
    await this.getVisitorTypeList();
    this.selectedVisitType = VisitType.MANAGEMENT_OFFICE;
    await this.getSettingInfo();
    this.startTime = this.getCurrentTime();
    this.endTime = this.getCurrentTime();
    await this.getPreInfo();
    
    // if (localStorage.getItem(this.LOCAL_STORAGE_FORM)) {
    //   this.showWarningDialog = true;
    //   this.warningMsg = `${this.$t("load_last_form")}`;
    // }

    this.$watch("selectedVisitType", async (newVal, oldVal) => {
      console.log("oldVal ---->", oldVal);
      this.selectedVisitType = newVal;
      if (this.selectedVisitType == VisitType.UNIT) {
        await this.getAllBlocks();
      }
    });
  },
  methods: {
    // formatDate(date) {
    //   if (!date) return '';
    //   const options = { day: '2-digit', month: 'short', year: 'numeric' };
    //   console.log("Show data add in ", Date(date).toLocaleDateString('en-GB', options));
    //   return new Date(date).toLocaleDateString('en-GB', options);
    // },
    showDatepicker() {
      this.$refs.datepicker.show(true);
    },
    customDateFormatter(date) {
      // const options = { year: 'numeric', month: 'short', day: '2-digit' };
      // return new Intl.DateTimeFormat('en-GB', options).format(date);
      const day = date.getDate().toString().padStart(2, '0');
      const month = date.toLocaleString('en', { month: 'short' });
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    },
    handleFileUpload(file, fileIndex) {
      const allowedTypes = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'image/bmp',
        'image/webp',
        'application/pdf',
      ];
      console.log("Show data passing in ", file);
      console.log("Show index passing in ", fileIndex);

      if (file && !allowedTypes.includes(file.type)) {
        // Prompt an alert to the user
        // Clear the specific selected file based on fileIndex
        if (fileIndex == 1) {
          // this.selectedFiles1 = null;
          this.$refs.fileInput1.reset();
        } else if (fileIndex == 2) {
          // this.selectedFiles2 = null;
          this.$refs.fileInput2.reset();
        } else if (fileIndex == 3) {
          // this.selectedFiles3 = null;
          this.$refs.fileInput3.reset();
        }
        alert('Only images and PDF files are allowed. Please select a valid file.');
      
      }else {
        // if (fileIndex == 1) {
        //   this.selectedFiles1 = file;
        // } else if (fileIndex == 2) {
        //   this.selectedFiles2 = file;
        // } else if (fileIndex == 3) {
        //   this.selectedFiles3 = file;
        // }
      }
    },
    setEndDate() {
      const startDate = moment(this.startDate);
      this.endDateMin = new Date(startDate);
      const newEndDate = startDate.add(1, "day");
      this.endDate = newEndDate.format("YYYY-MM-DD");
      this.endDatemax = startDate.add(this.maxDuration, this.durationType).format("YYYY-MM-DD");
      // this.startDate = this.formatDate(this.startDate);
    },
    setEndTime() {
      // const startTime = moment(this.startDate + this.startTime); // Ensure startTime is in a valid format
      // this.endTimeMin = startTime.format("hh:mm a"); // Format endTimeMin if needed
      // const newEndTime = startTime.clone().add(12, "hour"); // Clone to avoid mutating startTime
      // this.endTime = newEndTime.format("hh:mm a"); // Format endTime as required
      // console.log("Show change date time ", this.endTime);
      const startTime = moment(`${this.startDate} ${this.startTime}`);
      this.endTimeMin = new Date(startTime);
      const newEndTime = startTime.add(12, "hours");
      this.endTime = newEndTime.format("HH:mm:ss");
      console.log("Show change date time ", this.endTime);
    },
    getCurrentTime() {
      const currentDate = new Date();
      const hours = currentDate.getHours().toString().padStart(2, "0");
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");
      const seconds = currentDate.getSeconds().toString().padStart(2, "0");

      return `${hours}:${minutes}:${seconds}`;
    },
    showPicker() {
      // TODO: Still figuring how to open via code after user filled up the date
      this.$nextTick(() => this.$refs.picker.$el.click());
    },
    getPreInfo() {
      return new Promise((resolve, reject) => {
        this.spinner = true;
        axios.get(
            `${this.env.SERVER_URL}/angsaku/by-pass/limit-end-date?type=WALK_IN_FORM`
          )
          .then((res) => {
            let content = res.data.content;
            this.maxDuration = content.day;
            this.durationType = content.monthType;
            this.setEndDate();
            // this.endDatemax = this.startDate.add(this.maxDuration, "days").format("YYYY-MM-DD");
            this.spinner = false;
            resolve();
          })
          .catch((error) => {
            this.spinner = false;
            // alert(error);
            reject();
            const code = error.response.data.content;
            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }
            this.showErr = true;
            this.errMsg = this.$t(`error.code.${code}`);
          });
      });
    },
    getVisitorTypeList() {
      return new Promise((resolve, reject) => {
        this.spinner = true;
        axios.get(
            `${this.env.SERVER_URL}/angsaku/by-pass/visitor-walk-in-type`
          )
          .then((res) => {
            let content = res.data.content;

            // const mappedList = content.map(item => (item));

            this.visitTypeOptions = content;

            this.spinner = false;
            resolve();
          }).catch((error) => {
            this.spinner = false;
            // alert(error);
            reject();
            const code = error.response.data.content;
            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }
            this.showErr = true;
            this.errMsg = this.$t(`error.code.${code}`);
          });
      });
    },
    uploadFileApi(file) {
      return new Promise((resolve, reject) => {
        // this.spinner = true;
        const formData = new FormData();
        formData.append("file", file);
        axios.post(
            `${this.env.SERVER_URL}/angsaku/visitor-management/visitor/upload-visitor-walk-in-attachment`,
            formData
          )
          .then((res) => {
            let content = res.data.content;
            resolve(content);
          })
          .catch((error) => {
            this.spinner = false;
            // alert(error);
            reject();
            const code = error.response.data.content;
            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }
            this.showErr = true;
            this.errMsg = this.$t(`error.code.${code}`);
          });
      });
    },
    getSettingInfo() {
      return new Promise((resolve, reject) => {
        this.spinner = true;
        axios
          .get(
            `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/settings/additional-info/list/visitor`
          )
          .then((res) => {
            let content = res.data.content;
            this.addInfo = JSON.stringify(content.additionalInfoSettings);
            this.maxVisitor = +content.settingInfoDAO.value;
            this.addVisitor();
            this.spinner = false;
            resolve();
          })
          .catch((error) => {
            this.spinner = false;
            // alert(error);
            reject();
            const code = error.response.data.content;
            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }
            this.showErr = true;
            this.errMsg = this.$t(`error.code.${code}`);
          });
      });
    },
    getAllBlocks() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        axios
          .get(`${this.env.SERVER_URL}/angsaku/multi-unit/block/get-all-block`)
          .then((res) => {
            this.loading = false;
            if (!res) return;
            this.selectedFloor = null;
            this.selectedUnit = null;

            if(this.env.isLanded) {
              this.blockOptions = [
              { id: null, blockName: this.$t("select_phase") },
              ...res.data.content,
              ];
            } else {
              this.blockOptions = [
              { id: null, blockName: this.$t("select_block") },
              ...res.data.content,
              ];
            }
            
            resolve();
          })
          .catch((error) => {
            this.loading = false;
            reject();
            const code = error.response.data.content;
            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }
            this.showErr = true;
            this.errMsg = this.$t(`error.code.${code}`);
          });
      });
    },
    getFloors() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${this.env.SERVER_URL}/angsaku/multi-unit/block/get-all-floor/${this.selectedBlock}`,
          )
          .then((res) => {
            if (!res) return;
            this.selectedUnit = null;
            if(this.env.isLanded) {
              this.floorOptions = [
              { id: null, floorName: this.$t("select_jalan") },
              ...res.data.content,
              ];
            } else {
              this.floorOptions = [
              { id: null, floorName: this.$t("select_floor") },
              ...res.data.content,
            ];
            }
            resolve();
          })
          .catch((error) => {
            reject();
            const code = error.response.data.content;
            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }
            this.showErr = true;
            this.errMsg = this.$t(`error.code.${code}`);
          });
      });
    },
    getUnits() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${this.env.SERVER_URL}/angsaku/multi-unit/block/get-all-unit/${this.selectedFloor}`
          )
          .then((res) => {
            if (!res) return;
            this.unitOptions = [
              { id: null, unitName: this.$t("select_unit") },
              ...res.data.content,
            ];
            resolve();
          })
          .catch((error) => {
            reject();
            const code = error.response.data.content;
            if (!code) {
              this.showErr = true;
              this.errMsg = this.$t(`error.code.unknown`);
              return;
            }
            this.showErr = true;
            this.errMsg = this.$t(`error.code.${code}`);
          });
      });
    },
    validateRecaptha(response) {
      this.$v.form.recaptcha.$model = response;
    },
    addVisitor() {
      if (this.visitorList.length == this.maxVisitor) {
        return;
      }
      // Cannot pass this.addInfo to this.visitorList, Vue's will read this.addInfo's value
      let addInfo = this.formatAddInfo();
      this.visitorList.push({ addInfo: addInfo });
    },
    formatAddInfo() {
      const infoList = JSON.parse(this.addInfo);

      return infoList.map((data) => {
        data.value = "";
        data.date = "";
        data.time = "";
        return data;
      });
    },
    removeVisitor(index) {
      this.visitorList.splice(index, 1);
    },
    // get isValidTime() {
    //   return moment(this.endTime, this.format).isAfter(moment(this.startTime, this.format));
    // },
    saveWalkInFormToLocal(body) {
      const walkInForm = { ...body };
      walkInForm.visitorInfoDAOList = this.visitorList;
      walkInForm.selectedBlock = this.selectedBlock;
      walkInForm.selectedFloor = this.selectedFloor;
      walkInForm.selectedUnit = this.selectedUnit;
      localStorage.setItem(this.LOCAL_STORAGE_FORM, JSON.stringify(walkInForm));
    },
    async handleSubmit() {
      try {
        const lastFormValues = JSON.parse(
          localStorage.getItem(this.LOCAL_STORAGE_FORM)
        );
        if (lastFormValues) {
          this.title = lastFormValues.title;
          this.email = lastFormValues.visitorEmail;
          this.carPlateNo = lastFormValues.carPlateNumber;
          this.visitorList = lastFormValues.visitorInfoDAOList;

          if (
            lastFormValues.selectedBlock != null &&
            lastFormValues.selectedFloor != null &&
            lastFormValues.selectedUnit != null
          ) {
            this.selectedBlock = lastFormValues.selectedBlock;
            this.selectedVisitType = VisitType.UNIT;
            setTimeout(async () => {
              await this.getFloors();
              this.selectedFloor = lastFormValues.selectedFloor;
              await this.getUnits();
              this.selectedUnit = lastFormValues.selectedUnit;
            }, 500);
          }
        }
        localStorage.removeItem(this.LOCAL_STORAGE_FORM);
        this.showWarningDialog = false;
      } catch (error) {
        console.error("Handle submit error ->", error);
        localStorage.removeItem(this.LOCAL_STORAGE_FORM);
        this.showWarningDialog = false;
      }
    },
    handleCancel() {
      this.title = "";
      this.email = "";
      this.carPlateNo = "";
      this.showWarningDialog = false;
      localStorage.removeItem(this.LOCAL_STORAGE_FORM);
    },
    async onSubmit() {
      let message = "";
      let emailPatt = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z.]{2,}$/;

      // if (this.startDate == this.endDate && !this.isValidTime) {
      //   message += `To date cannot be earlier than from date<br>`;
      // }

      // if (this.email.length == 0) {
      //   message += `${this.$t("vstr-rsdnt.email")}: ${this.$t(
      //     "vstr-rsdnt.email"
      //   )} ${this.$t("validation.required")}<br>`;
      // } else
      if (this.email) {
        if (this.email.length < 6) {
          message += `${this.$t("vstr-rsdnt.email")}: ${this.$t(
            "validation.minimun"
          )} 10 ${this.$t("validation.characters")}<br>`;
        } else if (!emailPatt.test(this.email)) {
          message += `${this.$t("vstr-rsdnt.email")}: ${this.$t(
            "validation.email"
          )}<br>`;
        }
      }

      const visitorInfoList = this.visitorList.map((data, i) => {
        const addInfoList = data.addInfo
          .map((data2) => {
            // Combine date and time to value //
            if (data2.type == "DATE_TIME") {
              data2.value = `${data2.date} ${data2.time}`;
            }

            // Validate required field //
            if (data2.value == "" && data2.required) {
              message += `${this.$t("vstr-rsdnt.visitor")} ${i + 1}: ${
                data2.label
              } ${this.$t("validation.required")}<br>`;
            } else {
              // Validate type and minlength //
              if (data2.type == "LONG_TEXT") {
                if (data2.value.length >= 1 && data2.value.length < 10) {
                  message += `${this.$t("vstr-rsdnt.visitor")} ${i + 1}: ${
                    data2.label
                  } ${this.$t("validation.minimun")} 10 ${this.$t(
                    "validation.characters"
                  )}<br>`;
                }
              } else if (data2.type == "SHORT_TEXT") {
                if (data2.value.length >= 1 && data2.value.length < 3) {
                  message += `${this.$t("vstr-rsdnt.visitor")} ${i + 1}: ${
                    data2.label
                  } ${this.$t("validation.minimun")} 3 ${this.$t(
                    "validation.characters"
                  )}<br>`;
                }
              }
            }

            // Filter our value == '' //
            if (data2.value == "" && !data2.required) {
              return;
            }

            return {
              id: data2.id,
              ctValue: data2.label,
              value: data2.value,
            };
          })
          .filter((x) => x !== undefined); // Filter undefined //

        return {
          visitorId: i + 1,
          visitorAdditionalInfoDAOList: addInfoList,
        };
      });

      if (
        this.selectedVisitType == VisitType.UNIT &&
        (this.selectedUnit == null ||
          this.selectedFloor == null ||
          this.selectedBlock == null)
      ) {
        message += `${this.$t("must_select")}<br>`;
      }

      if (message != "") {
        this.showErr = true;
        this.errMsg = message;
        return;
      }

      // let visitType = "MANAGEMENT_OFFICE";

      // if (this.selectedVisitType==VisitType.UNIT) {
      //   visitType = "UNIT";
      // }else if (this.selectedVisitType==VisitType.CONTRACTOR) {
      //   visitType = "CONTRACTOR"
      // }else if (this.selectedVisitType==VisitType.CLUBHOUSE) {
      //   visitType = "CLUBHOUSE"
      // }

      const dateFormat = "YYYY-MM-DD HH:mm:ss";
      const startDatetime = moment(
        `${this.startDate} ${this.startTime}`
      ).format(dateFormat);
      const endDatetime = moment(`${this.endDate} ${this.endTime}`).format(
        dateFormat
      );

      let idList = [];

      // console.log("Show ref value", this.$refs.fileInput1.files);

      if (this.$refs.fileInput1.files.length>0) {
        let file1 = await this.uploadFileApi(this.$refs.fileInput1.files[0]);
        // console.log("Show file id ", file1);
        idList.push(file1);
      }

      if (this.$refs.fileInput2.files.length>0) {
        let file2 = await this.uploadFileApi(this.$refs.fileInput2.files[0]);
        idList.push(file2);
      }

      if (this.$refs.fileInput2.files.length>0) {
        let file3 = await this.uploadFileApi(this.$refs.fileInput3.files[0]);
        idList.push(file3);
      }
      
      const body = {
        fromUnitId:
          this.selectedVisitType == VisitType.UNIT
            ? this.selectedUnit
            : 0,
        numOfCarPark: this.carPlateNo ? 1 : 0,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
        title: this.title,
        visitorEmail: this.email,
        carPlateNumber: this.carPlateNo,
        reCaptcha: this.$v.form.recaptcha.$model,
        visitorInfoDAOList: visitorInfoList,
        visitorWalkInType: this.selectedVisitType,
        attachmentIds: idList
      };

      // Register guard walk in form
      this.saveWalkInFormToLocal(body);

      console.log("Show data in selected File ", this.selectedFiles1);

      this.loading = true;

      axios
        .post(
          `${this.env.SERVER_URL}${process.env.VUE_APP_API_VISITOR}/visitor/invitation-registry-for-walk-in`,
          body
        )
        .then((res) => {
          this.loading = false;
          const urlParams = new URLSearchParams(
            new URL(res.data.content).search
          );
          const invitationId = urlParams.get("invitationId");

          this.$router.push({
            path: "/copy-link",
            query: {
              email: this.email,
              invitation: res.data.content,
              invitationId: invitationId,
            },
          });
        })
        .catch((error) => {
          // alert(error);
          this.loading = false;
          const code = error.response.data.content;

          if (!code) {
            this.showErr = true;
            this.errMsg = this.$t(`error.code.unknown`);
            return;
          }

          this.showErr = true;
          this.errMsg = this.$t(`error.code.${code}`);
        });
    },
  },
};
</script>
  
  <style>
h3 {
  margin-bottom: 12px !important;
}

hr {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.btn-sm {
  padding: 0.1rem 0.3em !important;
}

.attachment-margin {
  margin-top: 1rem !important;
}

.icon-size {
  height: 14px !important;
  width: 14px !important;
  min-width: 14px !important;
}

/* .scroll-container {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  } */
</style>